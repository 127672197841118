import axios from 'axios'

// actions
export const actions = {
    async index(_ctx, payload) {
        const { data } = await axios.get('/api/laporan/per-jenis-kelamin?page=' + payload.page + '&id_unit_kerja=' + payload.data.id_unit_kerja)
        return data
    },
    async autocomplete_opd_skpd(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/laporan/per-opd-skpd/autocomplete_opd_skpd?search=' + payload)
            return data
        } catch (error) {
            return []
        }
    },
    async export(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/laporan/per-jenis-kelamin/export?id_unit_kerja=' + payload)
            return data
        } catch (error) {
            return []
        }
    },
}
