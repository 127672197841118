import axios from "axios";
import qs from "qs";

// actions
export const actions = {
  async update(_ctx, payload) {
    try {
      let data = await axios.patch(
        "/api/layanan/verifikasi-mutasi-keluar/" + payload.id,
        qs.stringify(payload.data)
      );
      return data;
    } catch (error) {
      return [];
    }
  },
  async store(_ctx, payload) {
    try {
      let data = await axios.post(
        "/api/layanan/verifikasi-mutasi-keluar",
        qs.stringify(payload)
      );
      return data;
    } catch (error) {
      return [];
    }
  },
  async index(_ctx, payload) {
    const { data } = await axios.get(
      "/api/layanan/verifikasi-mutasi-keluar?page=" +
        payload.page +
        "&nama_layanan=" +
        payload.data.nama_layanan
        + '&nama_unit_kerja=' + (payload.data.nama_unit_kerja != null ? payload.data.nama_unit_kerja : '')
        + '&no_surat=' + (payload.data.no_surat != null ? payload.data.no_surat : '')
    );
    return data;
  },
};
